import React from "react";

export interface IIconProps {
	size: number;
	opacity?: string;
}

const GitHubIcon: React.FC<IIconProps> = ({ size, opacity = "1.0" }) => (
	<span>
		<svg
			viewBox="0 0 31 31"
			fill="currentColor"
			stroke="none"
			xmlns="http://www.w3.org/2000/svg"
			className={`w-${size} h-${size} text-black dark:text-white`}
		>
			<path
				d="M15.4863 0.607911C7.03369 0.604493 0.1875 7.44727 0.1875 15.8931C0.1875 22.5718 4.47021 28.249 10.4346 30.334C11.2378 30.5356 11.1147 29.9648 11.1147 29.5752V26.9263C6.47656 27.4697 6.28857 24.4004 5.97754 23.8877C5.34863 22.8145 3.86182 22.541 4.30615 22.0283C5.3623 21.4849 6.43896 22.165 7.68652 24.0073C8.58887 25.3438 10.3491 25.1182 11.2412 24.896C11.436 24.0928 11.853 23.375 12.4272 22.8179C7.62158 21.9565 5.61865 19.0239 5.61865 15.5376C5.61865 13.8457 6.17578 12.2905 7.26953 11.0361C6.57227 8.96826 7.33447 7.19775 7.43701 6.93457C9.42285 6.75684 11.4873 8.35645 11.6479 8.48291C12.7759 8.17871 14.0645 8.01807 15.5068 8.01807C16.9561 8.01807 18.248 8.18555 19.3862 8.49317C19.7725 8.19922 21.6865 6.8252 23.5322 6.99268C23.6313 7.25586 24.3765 8.98535 23.7202 11.0259C24.8276 12.2837 25.3916 13.8525 25.3916 15.5479C25.3916 19.041 23.375 21.9771 18.5557 22.8247C18.9685 23.2307 19.2962 23.7148 19.5198 24.2488C19.7434 24.7829 19.8583 25.3561 19.8579 25.9351V29.7803C19.8853 30.0879 19.8579 30.3921 20.3706 30.3921C26.4238 28.3516 30.7817 22.6333 30.7817 15.8965C30.7817 7.44727 23.9321 0.607911 15.4863 0.607911Z"
				fillOpacity={opacity}
			/>
		</svg>
	</span>
);

const MediumIcon: React.FC<IIconProps> = ({ size, opacity }) => (
	<span>
		<svg
			viewBox="0 0 30 31"
			fill="currentColor"
			stroke="none"
			xmlns="http://www.w3.org/2000/svg"
			className={`w-${size} h-${size} text-black dark:text-white`}
		>
			<path
				d="M15 0.5C6.71652 0.5 0 7.21652 0 15.5C0 23.7835 6.71652 30.5 15 30.5C23.2835 30.5 30 23.7835 30 15.5C30 7.21652 23.2835 0.5 15 0.5ZM23.5714 8.99442L22.2054 10.3036C22.0848 10.394 22.0279 10.5413 22.0513 10.6853V20.3181C22.0279 20.4654 22.0848 20.6127 22.2054 20.6998L23.5446 22.0089V22.3002H16.8214V22.0223L18.2042 20.6797C18.3415 20.5424 18.3415 20.5022 18.3415 20.298V12.5033L14.4911 22.2667H13.9721L9.49219 12.5033V19.0491C9.45201 19.3237 9.54911 19.6016 9.7433 19.7991L11.5446 21.9788V22.2701H6.42857V21.9788L8.22991 19.7991C8.32482 19.7011 8.39545 19.5822 8.43615 19.4519C8.47685 19.3217 8.48648 19.1837 8.46428 19.0491V11.4821C8.48772 11.2712 8.40737 11.067 8.24665 10.923L6.6462 8.99442V8.70312H11.6183L15.4554 17.1239L18.8337 8.70982H23.5714V8.99442Z"
				fillOpacity={opacity}
			/>
		</svg>
	</span>
);

export { GitHubIcon, MediumIcon };
